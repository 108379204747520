import { default as _91_46_46_46slug_93ShhmX8LAkOMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/[...slug].vue?macro=true";
import { default as autologinJWlAJrBlFNMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/auth/autologin.vue?macro=true";
import { default as callbackJITGn57SYrMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/auth/callback.vue?macro=true";
import { default as bettingKG0Tf9QoEsMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/betting.vue?macro=true";
import { default as _91pageIdentity_93O7pdiiVVfwMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/bonus/[pageIdentity].vue?macro=true";
import { default as contactCjul526ARhMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/contact.vue?macro=true";
import { default as favorites_46clientukSGpdKjCTMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/favorites.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/platform/customer-frontends/cosmos/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_93gUN8zb9F9lMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/games/[id].vue?macro=true";
import { default as indexYX09mq0433Meta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/games/index.vue?macro=true";
import { default as indexoSBgPXDld1Meta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/index.vue?macro=true";
import { default as loyaltyKeIRH2iqMEMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/loyalty.vue?macro=true";
import { default as mainP07R7sritCMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/main.vue?macro=true";
import { default as bonusesbhDQjzZjzLMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/bonuses.vue?macro=true";
import { default as documents6XJENzXwfgMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/documents.vue?macro=true";
import { default as historyQ9ZlYQ87axMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/history.vue?macro=true";
import { default as info6GfuCoiarnMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/info.vue?macro=true";
import { default as limitsTynz7Je7SSMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/limits.vue?macro=true";
import { default as notificationsQ2t0hkGmOEMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/notifications.vue?macro=true";
import { default as securityqaftRJjoFpMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/security.vue?macro=true";
import { default as verification3pfnrS8bItMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/verification.vue?macro=true";
import { default as walletnUt6tK94fQMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/wallet.vue?macro=true";
import { default as profile_46clientV5mAODuicOMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/profile.client.vue?macro=true";
import { default as providersb99Dx0vnzGMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/providers.vue?macro=true";
import { default as _91pageIdentity_93I1kXxBiOvNMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/questions/[pageIdentity].vue?macro=true";
import { default as questionsxUPqn86JRDMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/questions.vue?macro=true";
import { default as recently_45played_46clientZjPcfFVx23Meta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/recently-played.client.vue?macro=true";
import { default as _91pageIdentity_93OCkuqgLzpaMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/static/[pageIdentity].vue?macro=true";
import { default as welcome_45packagejh8u9iZ61iMeta } from "/builds/platform/customer-frontends/cosmos/skeleton/pages/welcome-package.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/[...slug].vue")
  },
  {
    name: "auth-autologin",
    path: "/auth/autologin",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/auth/autologin.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/auth/callback.vue")
  },
  {
    name: "betting",
    path: "/betting",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/betting.vue")
  },
  {
    name: "bonus-pageIdentity",
    path: "/bonus/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/bonus/[pageIdentity].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/contact.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/favorites.client.vue"))
  },
  {
    name: "games-id",
    path: "/games/:id()",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/games/[id].vue")
  },
  {
    name: "games",
    path: "/games",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/games/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/index.vue")
  },
  {
    name: "loyalty",
    path: "/loyalty",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/loyalty.vue")
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/main.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/profile.client.vue")),
    children: [
  {
    name: "profile-bonuses",
    path: "bonuses",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/bonuses.vue")
  },
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/documents.vue")
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/history.vue")
  },
  {
    name: "profile-info",
    path: "info",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/info.vue")
  },
  {
    name: "profile-limits",
    path: "limits",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/limits.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/notifications.vue")
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/security.vue")
  },
  {
    name: "profile-verification",
    path: "verification",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/verification.vue")
  },
  {
    name: "profile-wallet",
    path: "wallet",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/profile/wallet.vue")
  }
]
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/providers.vue")
  },
  {
    name: "questions",
    path: "/questions",
    meta: questionsxUPqn86JRDMeta || {},
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/questions.vue"),
    children: [
  {
    name: "questions-pageIdentity",
    path: ":pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/questions/[pageIdentity].vue")
  }
]
  },
  {
    name: "recently-played",
    path: "/recently-played",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/recently-played.client.vue"))
  },
  {
    name: "static-pageIdentity",
    path: "/static/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/static/[pageIdentity].vue")
  },
  {
    name: "welcome-package",
    path: "/welcome-package",
    component: () => import("/builds/platform/customer-frontends/cosmos/skeleton/pages/welcome-package.vue")
  }
]